import { useGetMarkaziyApt } from "../../hooks/query";
import MarkaziyAprtItem from "./MarkaziyAprtItem";
import { useNavigate } from "react-router-dom";
import { TiArrowBack } from "react-icons/ti";
import { useTranslation } from "react-i18next";
import { Pagination } from "@mantine/core";
import { useState } from "react";

const MarkaziyAprt = () => {
  const [page, setPages] = useState(1);
  const useGetMarkaziyAptList = useGetMarkaziyApt({
    page_size: page,
  });

  const navigate = useNavigate();
  const { t } = useTranslation();
 
  return (
    <div className="mb-[40px]">
      <button
        onClick={() => navigate(-1)}
        className="lg:flex md:flex hidden md:ml-4  items-center gap-1 bg-[#15616D] text-white pr-4 px-3 rounded py-1 hover:bg-[#FF7D00] transition-all duration-200"
      >
        <TiArrowBack size={26} />
        {t("ortga")}
      </button>
      <h3 className="text-center my-[20px] text-3xl font-semibold">
        {t("Markaziy apparat")}
      </h3>
      {useGetMarkaziyAptList.data?.results.map((item) => (
        <MarkaziyAprtItem item={item} key={item.id} />
      ))}
      <Pagination
        py={28}
        color="cyan"
        size="lg"
        radius="xl"
        page={page}
        onChange={setPages}
        disabled={page >= 10}
        total={useGetMarkaziyAptList.data?.total_pages}
      />
    </div>
  );
};
export default MarkaziyAprt;
