import React from "react";
import gerb from "../../../assets/images/gerb.png";
import { Link } from "react-router-dom";
import Test from "../../../pages/test/Test";
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="h-[50px] bg-[#0A2647] items-center lg:px-0 md:px-4 px-6 border-b-2">
      <div className="max-w-[1280px] mx-auto h-full flex justify-between">
        <div className="flex items-center gap-2">
          <img
            src={gerb}
            width="34px"
            height="34px"
            alt="Gerb"
            className="object-cover"
          />
          <h1 className="text-white font-semibold lg:text-[21px] md:text-[18px] text-[14px] hover:text-blue-400 duration-300">
            <Link to="/">{t("Piskent tumani hokimligi")}</Link>
          </h1>
        </div>
        <div className="lg:flex items-center md:flex hidden text-white">
          <Test />
        </div>
        <div className="lg:flex md:flex hidden items-center gap-2 text-white mr-10">
          <p className="text-[18px]">
            {t("Ishonch Telefoni:")} +998 70 202 20 12
          </p>
        </div>
      </div>
    </div>
  );
};

export default Header;
